<template>
  <div class="main-wrap">
    <div class="info-box">
      <div class="left-inner">
        <!-- 个人信息 -->
        <h4>个人信息</h4>
        <div class="info">
          <div class="info-side-left">
            <p><span>姓名：</span>{{ form.name }}</p>
            <p><span>证件号码：</span>{{ form.idcard }}</p>
            <p><span>学历：</span>{{ form.education }}</p>
            <p><span>职称/职务：</span>{{ form.post }}</p>
            <!-- <p><span>工作单位：</span>{{ form.workunit }}</p> -->
          </div>
          <div class="info-side-right">
            <img
              :src="form.certifimobile"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="right-inner">
        <h4>考试须知</h4>
        <div class="notice">
          <div class="notice-item" v-for="(item,index) in noticeList" :key="index">
            <i>{{index + 1}}</i>
            <span>{{item}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 考试倒计时 -->
    <div class="time-box" v-show="seconds > 0 ">
      离考试开始时间还有
      <p><span>{{minute}}</span>分钟 <span>{{second}}</span>秒</p>
      请耐心等待！
    </div>
    <!-- 勾选 -->
    <div class="read-box"  v-show="seconds <= 0 ">
      <a-checkbox v-model="checked">我已认真阅读考试须知</a-checkbox>
    </div>
    <!-- 开考按钮 -->
    <div class="btn-box">
      <a-button type="primary" class="btn" :loading="loading" :disabled="isStarExam" @click="goExamSystem()"
        >开始考试</a-button
      >
      <a-button type="primary" class="btn" ghost @click="$router.push('/ExamView/ExamHome')"
        >暂不考试</a-button
      >
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      isStarExam: true,  // 是否开考
      checked:false,
      seconds: 0,
      minute: '00',
      second: '00',
      loading: false,
      form: {},
      noticeList:[],
    };
  },
  // 事件处理器
  methods: {
    // 考试系统
    goExamSystem() {
      if(this.form.examendtime=='00:00:00'){
        this.$message.error('考试时间已结束，无法进入！');
        return
      }
      if(this.$store.state.userInfo.isSubmit){
        this.$message.error('您已退出考试系统，试卷已强制提交，无法进入！');
        return
      }
      if(!this.checked){
        this.$message.error('请勾选考试须知');
        return
      }
      let obj = this.$store.state.userInfo
      obj.configId = this.form.configId
      obj.paperid = this.form.paperid
      obj.examineeid = this.form.examineeid
      this.$store.commit("updateUserInfo", obj);  // 更新个人信息
      this.loading = true
      this.$router.push({ path: "/ExamView/ExamSystem" });

      this.onRecordNum()  // 记录进场次数
    },

    onRecordNum(){
      this.$ajax({
        method: "POST",
        url: "/exam/system/login/" + this.$store.state.userInfo.examineeId,
      })
    },

    // 获取考试须知
    getExamInstructions(){
      this.$ajax({
        method: "get",
        url: "/exam/system/getExamSystemDesc",
        params: {
          configId: this.form.configId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success && res.message) {
          // 考试须知一并返回，需按#处理分割字符
          this.noticeList = res.message.split('#')
          // let hour = Number(this.form.duration.split(':')[0]) * 60
          // let min = Number(this.form.duration.split(':')[1])
          // this.noticeList[3]=('考试答题时间' + ( hour + min )+ '分钟，考试结束后立刻出成绩。')
        } else {
          this.noticeList=[]
        }
      });
    },

    // 获取个人信息
    getUserInfo() {
      this.$ajax({
        method: "get",
        url: "/exam/system/examinee/get",
        params: {
          signupuserid: this.$store.state.userInfo.signupuserid,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          let map = res.data;
          for (let item in map) {
            this.form = res.data[item];
          }

          this.getExamInstructions()

          // 开考剩余时间
          this.timeToSec(this.form.examdatetime)
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 开考剩余时间转换毫秒
    timeToSec(time) {
      var hour = time.split(':')[0]
      var min = time.split(':')[1]
      var sec = time.split(':')[2]
      var s = Number(hour * 3600) + Number(min * 60) + Number(sec)
      this.seconds = s
      if(this.seconds){
        this.timeout = setInterval(() => {
          this.seconds -= 1;
          if(this.seconds < 0) {
            clearTimeout(this.timeout)
            this.isStarExam = false
            return
          }
          this.countDown();
        }, 1000);
      } else {
        this.isStarExam = false
      }
    },
    // 开考倒计时
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60));
      d = d < 10 ? "0" + d : d;
      let h = parseInt((this.seconds / (60 * 60)) % 24);
      h = h < 10 ? "0" + h : h;
      let m = parseInt((this.seconds / 60) % 60);
      this.minute = m < 10 ? "0" + m : m;
      let s = parseInt(this.seconds % 60);
      this.second = s < 10 ? "0" + s : s;
    }, //定时器没过1秒参数减1

    // 监听是否在本页面
    monitor(e) {
      let isExist = e.target.visibilityState
      if (isExist === 'visible') {
        // console.log(new Date().toLocaleString(), `您已进入页面！`)
      } else {
        clearTimeout(this.timeout);
        this.getUserInfo();
        // console.log(new Date().toLocaleString(), `您已离开页面！`)
      }
    },
    
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 监听是否离开页面
    document.addEventListener('visibilitychange', this.monitor)
    this.getUserInfo();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.monitor)
    clearTimeout(this.timeout);
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.main-wrap {
  min-width: 800px;
  h4 {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    line-height: 23px;
    margin-bottom: 10px;
  }
  .info-box {
    display: flex;
    justify-content: space-between;
    margin: 20px 30px;
    margin-bottom: 60px;
    .left-inner,.right-inner{
      width: 50%;
      text-align: left;
    }
    .info{
      display: flex;
      align-items: center;
      .info-side-left {
        > p {
          text-align: left;
          font-size: 16px;
          font-weight: 500;
          color: #2f2e41;
          margin: 13px 0;
          line-height: 30px;
          span {
            display: inline-block;
            text-align: left;
            width: 100px;
            color: #666666;
          }
        }
      }
      .info-side-right {
        margin-left: 30px;
        margin-right: 20px;
        img {
          width: 104px;
          height: 151px;
          background-repeat: no-repeat;
          object-fit: cover;
        }
      }
    }
    .notice{
      margin-top: 30px;
      .notice-item{
        padding-left: 18px;
        padding-bottom: 18px;
        border-left: 2px dashed #D6E6FF;
        position: relative;
        span{
          color: #333333;
          font-size: 14px;
          line-height: 25px;
          position: relative;
          top: -4px;
        }
        i{
          position: absolute;
          font-style: normal;
          text-align: center;
          font-size: 12px;
          width: 18px;
          height: 18px;
          line-height: 18px;
          color: #fff;
          background: #2E6FDE;
          border-radius: 50%;
          left: -10px;
          top: 0px;
        }
      }
      .notice-item:last-child{
        border-left: 2px dashed transparent;
      }
    }
  }
  .time-box {
    margin-bottom: 15px;
    font-weight: 400;
    color: #333333;
    > p {
      display: inline-block;
      margin: 0 8px;
    }
    span {
      font-size: 24px;
      color: #ff0000;
    }
  }

  .read-box{
    margin-bottom: 13px;
  }
  
  .btn-box {
    .btn {
      min-width: 110px;
    }
    .btn:nth-child(1) {
      background: linear-gradient(180deg, #3681f0 0%, #2263c5 100%);
      margin-right: 70px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .main-wrap{
    width: 90%;
    min-width: 0;
  }
}
</style>
